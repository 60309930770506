import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <pre {...{
      "className": "default-dark vscode-highlight",
      "data-language": "markdown"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<!--`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  Thanks for wanting to contribute to this project!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  To help things move along, provide a description of your changes below and a general summary in the title`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  Please look at the following checklist to ensure that your PR can be accepted quickly:`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`-->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4 mtkb"
          }}>{`## Description and Context`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<!-- Describe your changes in detail and provide helpful context.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<!-- Why is this change required? What problem does it solve? -->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<!-- If it fixes an open issue, please link to the issue here. Other related links are encouraged! -->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4 mtkb"
          }}>{`## Type of Change:`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<!-- What types of changes does your code introduce? Put an \\\`x\\\` in all the boxes that apply: -->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk16"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [ ] Bug fix (non-breaking change which fixes an issue)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk16"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [ ] New feature (non-breaking change which adds functionality)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk16"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [ ] Breaking change (fix or feature that would cause existing functionality to change)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk16"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [ ] Refactor / internal changes (changes do not affect functionality, but are an improvement to the codebase)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4 mtkb"
          }}>{`## Screenshots`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<!-- If your changes affect how information is displayed or gathered (especially in a UI, report, or CLI), include a screen shot here --->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<!-- Annotated before and after screenshots are encouraged! --->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4 mtkb"
          }}>{`## Checklist`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<!-- Go over all the following points, and put an \\\`x\\\` in all the boxes that apply. -->`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk16"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [ ] I added tests to cover my changes`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk16"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [ ] All new and existing tests are passing`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk16"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [ ] I formatted and linted the code according to repo specs`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk16"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [ ] I rebased changes with main so that they can be merged easily`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk16"
          }}>{`-`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` [ ] I have updated the documentation accordingly (if applicable)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<!--`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    Thanks for adding all that information! `}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    Once you open the PR, go through the code yourself and add any comments in places where additional context or clarity might be helpful to reviewers. `}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    If you had a hard time choosing between a few different implementation solutions, provide a little context as to why and what other solutions you considered.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    Thanks for helping us keep a smooth and low-friction code review practice!`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`-->`}</span></span></code></pre>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  :root {
  --vscode-highlight-padding-v: 1rem;
  --vscode-highlight-padding-h: 1.5rem;
  --vscode-highlight-padding-top: var(--vscode-highlight-padding-v);
  --vscode-highlight-padding-right: var(--vscode-highlight-padding-h);
  --vscode-highlight-padding-bottom: var(--vscode-highlight-padding-v);
  --vscode-highlight-padding-left: var(--vscode-highlight-padding-h);
  --vscode-highlight-border-radius: 8px;

  --vscode-highlight-line-highlighted-background-color: transparent;
  --vscode-highlight-line-highlighted-border-width: 4px;
  --vscode-highlight-line-highlighted-border-color: transparent;
}

.vscode-highlight {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-top: 1rem;
  padding-top: var(--vscode-highlight-padding-top);
  padding-bottom: 1rem;
  padding-bottom: var(--vscode-highlight-padding-bottom);
  border-radius: 8px;
  border-radius: var(--vscode-highlight-border-radius);
  font-feature-settings: normal;
}

.vscode-highlight-code {
  display: inline-block;
  min-width: 100%;
}

.vscode-highlight-line {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding-left: 1.5rem;
  padding-left: var(--vscode-highlight-padding-left);
  padding-right: 1.5rem;
  padding-right: var(--vscode-highlight-padding-right);
}

.vscode-highlight-line-highlighted {
  background-color: var(--vscode-highlight-line-highlighted-background-color);
  box-shadow: inset var(--vscode-highlight-line-highlighted-border-width) 0 0 0 var(--vscode-highlight-line-highlighted-border-color);
}

  .default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.default-dark .mtk1 { color: #D4D4D4; }
.default-dark .mtk2 { color: #1E1E1E; }
.default-dark .mtk3 { color: #6A9955; }
.default-dark .mtk4 { color: #569CD6; }
.default-dark .mtk5 { color: #D16969; }
.default-dark .mtk6 { color: #D7BA7D; }
.default-dark .mtk7 { color: #B5CEA8; }
.default-dark .mtk8 { color: #CE9178; }
.default-dark .mtk9 { color: #646695; }
.default-dark .mtk10 { color: #4EC9B0; }
.default-dark .mtk11 { color: #DCDCAA; }
.default-dark .mtk12 { color: #9CDCFE; }
.default-dark .mtk13 { color: #000080; }
.default-dark .mtk14 { color: #F44747; }
.default-dark .mtk15 { color: #C586C0; }
.default-dark .mtk16 { color: #6796E6; }
.default-dark .mtk17 { color: #808080; }
.default-dark .mtki { font-style: italic; }
.default-dark .mtkb { font-weight: bold; }
.default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      